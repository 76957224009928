import { postApi, getApi } from '../config/http'

//登录
export const longinApi = async (data: any): Promise<void> => postApi(`/atm/psbc/login${data.generateGuid}`, data.data)

//用户列表
export const userlistApi = async (data: any): Promise<void> => postApi(`/atm/psbc/owner/userlist${data.generateGuid}`, data.data)

//教育缴费账单
export const educationApi = async (data: any): Promise<void> => postApi(`/atm/psbc/education/list${data.generateGuid}`, data.data)

//绑定
export const bindApi = async (data: any): Promise<void> => postApi(`/atm/psbc/owner/bind${data.generateGuid}`, data.data)

//绑定oid
export const bindinfoApi = async (data: any): Promise<void> => postApi(`/atm/psbc/owner/bindinfo${data.generateGuid}`, data.data)

//绑定oid
export const switchApi = async (data: any): Promise<void> => postApi(`atm/psbc/switch${data.generateGuid}`, data.data)