<template>
  <div v-if="!loading"></div>
  <div v-else>
    <div v-if="collapsea">
     <div v-if="list.length > 0">
      <div class="home" v-if="collapsea">
      <TopNav :back="true" text="智慧校园" :clone="0" color="#FFFFFF" />
      <div class="name w">
        <div class="nameleft">
          <div class="nametouxiang">
            <!-- <img v-if="listitem.uidtype == 1" :src=" listitem.gender == 'M' ? '../assets/img_common_femaleteadef.png':'../assets/img_common_masterdef.png'" alt="" /> -->
            <img
              v-if="listitem.uidtype == 1 && listitem.gender == 'M'"
              src="../assets/img_common_masterdef.png"
              alt=""
            />
            <img
              v-if="listitem.uidtype == 1 && listitem.gender == 'F'"
              src="../assets/img_common_femaleteadef.png"
              alt=""
            />
            <img
              v-if="listitem.uidtype == 2 && listitem.gender == 'M'"
              src="../assets/img_common_boystudef.png"
              alt=""
            />
            <img
              v-if="listitem.uidtype == 2 && listitem.gender == 'F'"
              src="../assets/img_common_girlstudef.png"
              alt=""
            />
          </div>
          <div class="detlid">
            <div class="detopadd">
              <div class="detop">{{ listitem.name }}</div>
              <div class="nameright" v-if="list.length > 1">
                <div class="nameicon"></div>
                <div class="titles" @click="qiehuan()">切换</div>
                <div class="tanchuang" v-if="show">
                  <div
                    class="boxsitems"
                    v-for="(item, index) in list"
                    :key="index"
                    @click="huanren(item, index)"
                  >
                    <div
                      class="shu"
                      :style="
                        active == index
                          ? 'background: linear-gradient(180deg, #5a9ffd 0%, #46d4d6 100%);'
                          : ''
                      "
                    ></div>
                    <div class="shutoux">
                      <img
                        v-if="item.uidtype == 1 && item.gender == 'M'"
                        src="../assets/img_common_masterdef.png"
                        alt=""
                      />
                      <img
                        v-if="item.uidtype == 1 && item.gender == 'F'"
                        src="../assets/img_common_femaleteadef.png"
                        alt=""
                      />
                      <img
                        v-if="item.uidtype == 2 && item.gender == 'M'"
                        src="../assets/img_common_boystudef.png"
                        alt=""
                      />
                      <img
                        v-if="item.uidtype == 2 && item.gender == 'F'"
                        src="../assets/img_common_girlstudef.png"
                        alt=""
                      />
                    </div>
                    <div class="nametouxiangs">{{ item.name }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="debottom">{{ listitem.schoolname }}</div>
          </div>
        </div>
        <div class="namesadder"  @click="detail(listitem)">
          <div class="nameadderleft">
            <img src="@/assets/qwe.png" alt="" />
          </div>
          <div class="nameadderright">个人中心</div>
        </div>
      </div>
      <div class="boxs w" v-if="educationList.length > 0">
        <TopText text="教育缴费"></TopText>
        <!-- @click="goPay(item.eid)" -->
        <div
          class="conters"
          v-for="(item, index) in educationList"
          :key="index"
          @click="goPay(item.orderid)"
        >
          <!-- <a > -->
          <div class="one">{{ item.ordername }}</div>
          <div class="two">
            <div class="twoleft">{{ listitem.name }}</div>
            <div class="tworight">￥ {{ item.amount_total }}</div>
          </div>
          <div class="there">
            <div class="thereleft">缴费截止时间：{{ item.validity }}</div>
            <div class="thereright">去缴费</div>
          </div>
          <!-- </a> -->
        </div>
      </div>
      <!-- <div class="boxs w">
        <TopText text="包餐缴费"></TopText>
        <div class="conters">
          <div class="one">账单名称</div>
          <div class="two">
            <div class="twoleft">刘文文</div>
            <div class="tworight">￥ 1500.00</div>
          </div>
          <div class="there">
            <div class="thereleft">缴费截止时间：2023-12-25 23:59</div>
            <div class="thereright">去缴费</div>
          </div>
        </div>
        <div class="conters">
          <div class="one">账单名称</div>
          <div class="two">
            <div class="twoleft">刘文文</div>
            <div class="tworight">￥ 1500.00</div>
          </div>
          <div class="there">
            <div class="thereleft">缴费截止时间：2023-12-25 23:59</div>
            <div class="thereright">去缴费</div>
          </div>
        </div>
      </div> -->
      <div class="null w" v-else>
        <div class="image">
          <div class="tet">暂无待缴费账单</div>
        </div>
      </div>
    </div>
     </div>
    <!-- 切换学校  -->
   <div v-else>
    <div class="zanwu" v-if="collapsea">
      <div class="zanwutop">
        <TopNav :back="true" text="智慧校园" color="#333333" />
      </div>
      <div class="w">
        <div class="zanwumain">
          <img src="../assets/zanwu.png" alt="" />
        </div>
        <div class="zanwumain">
          <p>
            您目前尚未关联学生或教师信息，请打开支付宝搜索“知校企校园”小程序完成相关信息添加。
          </p>
        </div>
      </div>
      <div class="zanwubut" @click="switchSchool()">切换学校</div>
    </div>
   </div>
    </div>
    <div v-else>
      <div class="about">
        <TopNav :back="true" text="" color="#333333" />
      </div>
      <div style="height: 12px"></div>
      <div class="lianxifs">联系方式</div>
      <div class="input">
        <div class="input_left">
          <img src="@/assets/iphone.png" alt="" />
        </div>
        <div class="input_right">
          <input
            type="text"
            name=""
            id=""
            placeholder="请输入注册学校时预留的联系方式"
            v-model="Phone"
            @change="getPhone"
          />
        </div>
      </div>
      <div class="button" @click="bind()">绑定</div>
      <div class="button1" @click="switchSchool()">切换学校</div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { reactive, ref, onMounted } from "vue";
import { Options, Vue } from "vue-class-component";
import TopNav from "@/components/TopNav.vue";
import TopText from "@/components/TopText.vue";
import { useRouter } from "vue-router";
import { longinApi, userlistApi, educationApi, bindApi } from "@/api/api";
import store from "@/store";
import { generateGuid } from "@/utils/rand";
import { switchApi } from "@/api/api";

async function switchSchool() {
  let res: any = await switchApi({ generateGuid: generateGuid(), data });
  if (res.errno == 0) {
    window.location.href = res.data.url;
  }
}

import state from "../store";
const collapsea: any = ref(false);
const show: any = ref(false);
const educationList: any = ref([]);
const list: any = ref([]);
const active: any = ref(0);
const type: any = ref(1);
const num: any = ref(10);
const offset: any = ref(0);
const Phone: any = ref("");
const data = list.value;
const router = useRouter();
const loading = ref(false);
import { showFailToast } from "vant";
function detail(value: any) {
  //跳转/acc路由并携带value参数
  router.push({
    path: "/acc",
    query: {
      value: JSON.stringify(value),
    },
  });
}

function goPay(value: any) {
  console.log(value);

  //   // let queryEncode = encodeURIComponent({a:1});
  //   let params = ``;
  console.log(listitem.value.uid);

  window.location.href = `alipays://platformapi/startApp?appId=2021003194656719&page=/pages/payment-details/payment-details&query=a={"uid":"${listitem.value.uid}","uidtype":"${listitem.value.uidtype}","orderid":"${value}"}`; //${encodeURIComponent({uid:listitem.uid,uidtype:listitem.uidtype,orderid:value})}
}
// https://ds.alipay.com/?scheme=
function qiehuan() {
  show.value = !show.value;
}

const getPhone = () => {
  console.log(Phone.value);
};

async function bind() {
  if (Phone.value) {
    let data = {
      mobile: Phone.value,
    };
    let res: any = await bindApi({
      data,
      generateGuid: generateGuid(),
    });
    if (res.errno == 0) {
      collapsea.value = true;
      userlist();
    } else {
    }
  } else {
    showFailToast("手机号不能为空");
  }
}

function huanren(e: any, index: number) {
  active.value = index;
  listitem.value = list.value[active.value];
  show.value = false;
  // console.log(listitem.value);

  education();
}

async function login() {
  const data = {
    // auth_code: "123456789",
    // city_code: "110100",
    // school_name: "河北万旗学校",
    auth_code: state.getters.authCode,
    city_code: state.getters.cityCode,
    school_name:state.getters.campusName ,
  };
  const res: any = await longinApi({ generateGuid: generateGuid(), data });
  if (res.errno == 0) {
    store.commit("SET_TOKEN", res.data.token);
    loading.value = true;
    if (res.data.info.status == 1) {
      collapsea.value = true;
      console.log("SET_TOKEN");

      userlist();
    }
  }
}
const listitem: any = ref("");
async function userlist() {
  const res: any = await userlistApi({ generateGuid: generateGuid() });
  if (res.errno == 0) {
    if(res.data){
list.value = res.data;
    listitem.value = res.data[active.value];
    education();
    }
    
  }
}

async function education() {
  // console.log(list.value[active.value]);

  const data = {
    uid: list.value[active.value].uid,
    uidtype: list.value[active.value].uidtype,
    type: type.value,
    num: num.value,
    offset: offset.value,
  };
  const res: any = await educationApi({ generateGuid: generateGuid(), data });
  if (res.errno == 0) {
    educationList.value = res.data.data;
    console.log(educationList.value);
  }
}

if (state.getters.shou) {
  store.commit("SET_SHOU", false);
  console.log(state.getters.shou);

  login();
} else {
  loading.value = true;
  collapsea.value = true;
  userlist();
}
function handleUpdateMessage(newMessage: any) {
  console.log(12);
}

// @Options({
//   components: {
//     TopNav,
//     TopText,
//   },
// })
// export default class HomeView extends Vue {}
</script>

<style lang="scss" scoped>
.namesadder {
  width: 90px;
  height: 28px;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 600px 0px 0px 600px;
  position: relative;
  right: -5vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
.nameadderleft {
  width: 13px;
  height: 15px;
}

.nameadderright {
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 13px;
  color: #0e703f;
  line-height: 15px;
  text-align: left;
  font-style: normal;
  text-transform: none;
}
.detopadd {
  display: flex;
  align-items: flex-end;
}
.home {
  width: 100vw;
  height: 482px;
  background-image: url("@/assets/bg.png");
  background-size: 100% 100%;
}
.lianxifs {
  width: 90%;
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 20px;
  color: #333333;
  line-height: 23px;
  text-align: left;
  font-style: normal;
  text-transform: none;
  margin: 0 auto;
  margin-bottom: 12px;
}
.about {
  width: 100vw;
  height: 44px;
  background: #ffffff;
}
.zanwutop {
  width: 100vw;
  height: 44px;
  background: #ffffff;
}
.zanwu {
  height: 100vh;
  background: #ffffff;
}
.zanwu img {
  width: 233px;
  height: 162px;
  margin-top: 100px;
}
.zanwu p {
  width: 256px;
  height: 63px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 15px;
  color: #999999;
  line-height: 18px;
  text-align: center;
  font-style: normal;
  text-transform: none;
  margin-top: 9px;
}
.zanwumain {
  display: flex;
  align-items: center;
  justify-content: center;
}
.zanwubut {
  width: 90vw;
  height: 50px;
  background: #0e703f;
  border-radius: 10px 10px 10px 10px;
  position: fixed;
  bottom: 20px;
  left: calc(50% - 45vw);
  text-align: center;
  line-height: 50px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 17px;
  color: #ffffff;
}
.input {
  width: 90%;
  height: 50px;
  background: #ffffff;
  border-radius: 10px 10px 10px 10px;
  border: 2px solid #d9dada;
  margin: 0 auto;
  display: flex;
  align-items: center;
  .input_left {
    width: 13px;
    height: 19px;
    margin-left: 18px;
  }
  .input_left img {
    width: 100%;
    height: 100%;
  }
  .input_right {
    margin-left: 8px;
    flex: 1;
  }
  .input_right input {
    width: 100%;
    border: none;
  }
}
.button1 {
  width: 90vw;
  height: 50px;
  border-radius: 10px 10px 10px 10px;
  position: absolute;
  bottom: 20px;
  left: calc(50% - 45vw);
  color: #0e703f;
  border: 1px solid #0e703f;
  text-align: center;
  line-height: 50px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 17px;
}
.button {
  width: 90vw;
  height: 50px;
  background: #0e703f;
  border-radius: 10px 10px 10px 10px;
  position: absolute;
  bottom: 90px;
  left: calc(50% - 45vw);
  text-align: center;
  line-height: 50px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 17px;
  color: #ffffff;
}
.name {
  height: 44px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .nameleft {
    display: flex;
    .nametouxiang {
      width: 44px;
      height: 44px;
      // margin-left: 6px;
      // border-radius: 14px 14px 14px 14px;
      // border: 2px solid #ffffff;
      // box-sizing: border-box;
    }
    .nametouxiang img {
      width: 100%;
      height: 100%;
      border-radius: 14px 14px 14px 14px;
      border: 2px solid #ffffff;
      box-sizing: border-box;
    }
    .detlid {
      margin-left: 8px;
      .detop {
        height: 25px;
        font-family: PingFang SC, PingFang SC;
        font-weight: bold;
        font-size: 18px;
        color: #ffffff;
        line-height: 21px;
        text-align: left;
        font-style: normal;
        text-transform: none;
        margin-right: 6px;
      }
      .debottom {
        height: 18px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        font-size: 13px;
        color: #ffffff;
        line-height: 15px;
        text-align: left;
        font-style: normal;
        text-transform: none;
        margin-top: 4px;
      }
    }
  }
  .nameright {
    width: 60px;
    height: 26px;
    border-radius: 600px 600px 600px 600px;
    border: 1px solid #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .tanchuang {
      width: 141px;
      // height: 180px;
      padding: 8px 0;
      background: #ffffff;
      box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.14);
      border-radius: 14px 14px 14px 14px;
      position: absolute;
      top: 36px;
      right: 0;
      .boxsitems {
        width: 100%;
        height: 44px;
        // background-color: #ff7121;
        display: flex;
        align-items: center;
        margin-top: 5px;
        .shu {
          width: 3px;
          height: 30px;

          border-radius: 600px 600px 600px 600px;
        }
        .shutoux {
          width: 44px;
          height: 44px;
          border-radius: 14px 14px 14px 14px;
          margin-left: 10px;
          img {
            width: 100%;
            height: 100%;
            border-radius: 14px 14px 14px 14px;
          }
        }
        .nametouxiangs {
          margin-left: 10px;

          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          font-size: 16px;
          color: #333333;
          line-height: 19px;
          text-align: left;
          font-style: normal;
          text-transform: none;
        }
      }
    }
    .nameicon {
      width: 8px;
      height: 12px;
      background-image: url("@/assets/qiehuan.png");
    }
    .titles {
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      font-size: 13px;
      color: #ffffff;
      line-height: 15px;
      text-align: left;
      font-style: normal;
      text-transform: none;
      margin-left: 3px;
    }
  }
}
.boxs {
  margin-top: 14px;
  padding: 10px 12px;
  box-sizing: border-box;
  background: #ffffff;
  border-radius: 14px 14px 14px 14px;
  .conters {
    width: 100%;
    height: 103px;
    background: #f0fcff;
    border-radius: 10px 10px 10px 10px;
    margin-top: 8px;
    padding: 10px;
    box-sizing: border-box;
    .one {
      height: 21px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      font-size: 15px;
      color: #333333;
      line-height: 21px;
      text-align: left;
      font-style: normal;
      text-transform: none;
    }
    .two {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 4px;
      .twoleft {
        height: 21px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        font-size: 15px;
        color: #333333;
        line-height: 21px;
        text-align: left;
        font-style: normal;
        text-transform: none;
      }
      .tworight {
        height: 28px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        font-size: 20px;
        color: #ff7121;
        line-height: 23px;
        text-align: left;
        font-style: normal;
        text-transform: none;
      }
    }
    .there {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 4px;
      height: 26px;
      .thereleft {
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 11px;
        color: #999999;
        text-align: left;
        font-style: normal;
        text-transform: none;
      }
      .thereright {
        width: 62px;
        height: 26px;
        background: #0e703f;
        border-radius: 600px 600px 600px 600px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        font-size: 14px;
        color: #ffffff;
        line-height: 26px;
        text-align: left;
        font-style: normal;
        text-transform: none;
        text-align: center;
      }
    }
  }
}
.null {
  height: 291px;
  background: #ffffff;
  border-radius: 14px 14px 14px 14px;
  margin-top: 20px;
  .image {
    width: 274px;
    height: 274px;
    background-image: url(@/assets/null.png);
    margin: 0 auto;
    padding-top: 1px;
    .tet {
      height: 21px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      font-size: 15px;
      color: #999999;
      line-height: 18px;
      text-align: left;
      font-style: normal;
      text-transform: none;
      text-align: center;
      margin-top: 235px;
    }
  }
}
</style>
