import { createStore } from 'vuex'

export default createStore({
  state: {
    token: localStorage.getItem('token') ? localStorage.getItem('token') : '',
    // uid: localStorage.getItem('uid') ? localStorage.getItem('uid') : '0',
    project: 'psbc',
    platform: 'h5',
    version:'2.0.01',
    rand: '',
    time: '',
    guid: '',
    authCode:'',
    cityCode:'',
    campusName:'',
    shou:true
  },
  getters: {
    token: state => state.token,
    // uid: state => state.uid,
    project: state => state.project,
    platform: state => state.platform,
    version: state => state.version,
    rand: state => state.rand,
    time: state => state.time,
    guid: state => state.guid,
    authCode: state => state.authCode,
    cityCode: state => state.cityCode,
    campusName: state => state.campusName,
    shou: state => state.shou,
  },
  mutations: {
    // SET_UID(state, value) {
    //   state.uid = value
    //   state.uid ? localStorage.setItem('uid', state.uid) : localStorage.removeItem('uid')
    // },
    SET_TOKEN(state, value) {
      console.log(state, value);
      
      state.token = value
      state.token ? localStorage.setItem('token', state.token) : localStorage.removeItem('token')
    },
    SET_AUTOCODE(state, value){
      console.log(state, value);
      
      state.authCode = value
    },
    SET_CAMPUSNAME(state, value){
      console.log(state, value);

      state.campusName = value
    },
    SET_CITYCODE(state, value){
      console.log(state, value);

      state.cityCode = value
    },
    SET_SHOU(state, value){
      console.log(state, value);
      
      state.shou = value
    }
  },
  actions: {
  },
  modules: {
  }
})
