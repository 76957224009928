<template>
   <keep-alive exclude="Dashboard">
    <router-view/>
   </keep-alive>

</template>

<style lang="scss">

</style>
