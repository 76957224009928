import { md5 } from 'js-md5';
import _ from 'lodash'
import store from '../store'
import state from '../store'
const str: Array<string> = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z']

function generateRand(): string {
    var res: string = ""
    for (var i = 0; i < 15; i++) {
        var id: number = Math.ceil(Math.random() * 35)
        res += str[id]
    }
    return res
}

function generateTime(): Number {
    return new Date().getTime()
}

function generateGuid() {
    var getterObj = _.cloneDeep(store.getters);
    let obj: any = {}
    obj.platform = getterObj.platform,
        obj.project = getterObj.project,
        console.log(generateRand());
    obj.rand = generateRand(),
        obj.time = generateTime(),
        // obj.uid = getterObj.uid,
        obj.version = getterObj.version

    delete getterObj.token
    delete getterObj.guid
    let guid: string = ''
    for (const key in obj) {
        guid += `${key}=${obj[key]}`
    }
    guid += 'CFUQKY^9bP%nQqN#gX2v^ASQcuLB^jd!'
    guid = md5(guid)
    let platform: string = `?project=${state.getters.project}&platform=${state.getters.platform}&version=${state.getters.version}&rand=${obj.rand}&time=${obj.time}&guid=${guid}&token=${state.getters.token}`
    return platform
}
export { generateRand, generateTime, generateGuid }