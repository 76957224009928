import { createApp } from 'vue'
import App from './App.vue'
import '@/style/style.css'
import vant from 'vant';
import 'vant/lib/index.css';

import router from './router'

import store from './store'


const url = window.location.href;


function getQueryParams(url:any) {
    const params:any = {};
    const queryString = url.split('?')[1];
    if (queryString) {
      const pairs = queryString.split('&');
      pairs.forEach(function(pair:any) {
        const [key, value] = pair.split('=');
        params[decodeURIComponent(key)] = decodeURIComponent(value);
      });
    }
    return params;
  }

  const queryParams = getQueryParams(url);
  console.log(queryParams);
  
  store.commit("SET_AUTOCODE", queryParams.authCode);
  store.commit("SET_CAMPUSNAME", queryParams.campusName);
  store.commit("SET_CITYCODE", queryParams.cityCode);


createApp(App).use(store).use(router).use(vant).mount('#app')
