<template>
  <div class="top">
    <div class="nav" :style="{ color: color }">
      <div class="fanhui" v-if="back" @click="goBack()">
        <van-icon name="arrow-left" />
      </div>
      <div class="text">{{ text }}</div>
      <div class="right" @click="goBack1()">
        <img v-if="clone == 0" src="../assets/clone1.png" alt="">
        <img v-else src="../assets/clone.png" alt="">
      </div>
    </div>
   
  </div>
</template>

<script lang="ts" setup>
import { Icon } from "vant";



const props = defineProps<{
  back: Boolean;
  text: string;
  color: string;
  clone:Number;
  add:Number
}>();

function goBack() {
  // window.history.back();
  console.log(props.add);
  
  if(props.add != 1){
    AlipayJSBridge.call('gotoHomeYouBank',{
    homePageIndex:'4'
  })
  }else{
    window.history.back();
  }
}
function goBack1(){
  AlipayJSBridge.call('gotoHomeYouBank',{
    homePageIndex:'4'
  })
}

</script>

<style lang="scss" scoped>
.top {
  width: 100vw;
  //   height: 44px;
  //   background: #a45454;

  .nav {
    width: 90vw;
    height: 44px;
    // background: #615252;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    .text {
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      font-size: 17px;
      //   color: #7e2c2c;
      line-height: 44px;
      text-align: left;
      font-style: normal;
      text-transform: none;
    }
    .right{
      width: 20px;
      height: 20px;
      position: absolute;
      right: 0;
    }
    .right img{
      width: 100%;
      height: 100%;
    }
    .fanhui {
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      line-height: 44px;
      font-size: 17px;
      font-style: normal;
      text-transform: none;
      position: absolute;
      left: 0;
    }
  }
 
  
}
</style>